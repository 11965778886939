import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-refuse-request',
  templateUrl: './refuse-request.component.html',
  styleUrls: ['./refuse-request.component.scss']
})
export class RefuseRequestComponent implements OnInit{
  form!:FormGroup;
  submitted:boolean=false ; 
  deleted_eye:boolean=false;
  pass_type:any='password' ;
 
  constructor(public dialogRef: MatDialogRef<RefuseRequestComponent>,private formbuilder:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any , private toast:ToastrService , private service:GlobalService){}

  ngOnInit() {
    this.form = this.formbuilder.group({
      reason:[null,Validators.required],
    })
   // console.log("id",this.data.data)
   
  }
 

  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
  //  if(this.form.invalid){ return}
  let sent_form ={}
  if(this.data.data.from=='points') {
    sent_form ={
      status:3, 
      ...this.form.value
     }
     this.service. changePointsApproval(this.data.data.id,sent_form).subscribe((res:any)=>{
   //   console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         this.dialogRef.close();
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }
  else {
    sent_form ={
      status:2, 
      ...this.form.value
     }
     this.service.changeMovementStatus(this.data.data.id,sent_form).subscribe((res:any)=>{
     // console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         this.dialogRef.close();
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }
  
 
 
   }
}
