import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditComponent } from '../edit/edit.component';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  roles:any=[]
  data:any;
  showroles:boolean=false;
 
  constructor( titleService:Title, public dialog:MatDialog,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
    titleService.setTitle('قائمة المهام') 
  } 
  
  ngOnInit()  {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة المهام', active:true} 
    ]
    

    this.allRoles();
  }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  allRoles(){
    this.showroles=true ;
    this.spinner.show()
    this.service.roleList().subscribe((res:any)=>{
      this.spinner.hide()
      this.showroles=false ;
      this.roles=res['data'] 
      // console.log("roles",this.roles)
    })
  }

  Delete(role_id:any){
    this.service.deleteRole(role_id).subscribe((res:any)=>{
     this.toast.success('Success',res?.message)
     this.allRoles()
    },
    (err:any)=>{
    //  console.log(err.error)
     this.toast.error('Error',err?.error?.message)
    })
  }

  Edit(role:any) {
    const dialogRef = this.dialog.open(EditComponent, {
      width: '800px',
      data: {data:role , title:'Edit Role'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
     this.allRoles()
    });
  }
 
}
