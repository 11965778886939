import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
 

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit { // ,AfterViewInit  
 
  displayedColumns: string[] = ['id', 'body', 'created_at', 'updated_at' ] ;
  dataSource:any  
 
  ///////////////////////Filter////////////////////////
 
  notifications:any=[]
  data:any;
  shownotifications:boolean=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
  constructor(titleService:Title, public dialog:MatDialog,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService , public router:Router) {
    titleService.setTitle('قائمة الإشعارات') 
  }  
 
  ngOnInit()  {
   this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة الإشعارات', active:true} 
    ]
    
    this.pageIndex=1
    this.allNotifications(1,'')
  }
 
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  
  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    // console.log("onPaginateChange",event.pageIndex+1)   
    this.allNotifications(this.pageIndex,'')
  } 
 
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  allNotifications(page:any,link:any){
    this.shownotifications=true ;
    this.spinner.show();
    this.service.notificationList(page).subscribe((res:any)=>{
      this.shownotifications=false ;
      this.notifications=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.notifications);
      this.spinner.hide();
    //  console.log("notifications",this.notifications)
    })
  }
  
  changeNotificationToRead(notification_id:any , notification_type:any){
    this.service.changeNotificationStatus(notification_id).subscribe((res:any)=>{
     this.toast.success('Success',res?.message) 
     setTimeout(()=>{
        if(notification_type=='points'){
          this.router.navigate(['/new-requests/points'])
        }else {
          this.router.navigate(['/new-requests/bank']) 
        }
     },1000)
     this.allNotifications(this.pageIndex,'')
    },
    (err:any)=>{
    //  console.log(err.error)
     this.toast.error('Error',err?.error?.message)
    })
  }
 
}
