import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';  
 
import { SettingComponent } from './setting/setting.component';
 
const routes: Routes = [
  { path:'edit', component: SettingComponent} ,
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
 