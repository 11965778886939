import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
 
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  deleted_eye:boolean=false;
  // confirm_deleted_eye:boolean=false ;
  pass_type:any='password' ;
  // confirm_pass_type:any='password';
  email!: boolean;
  password!: boolean;
  data:any=[]
  roles:any=[]
 
  constructor(private titleService:Title ,private formbuilder:FormBuilder, private spinner:NgxSpinnerService ,
    private toastr:ToastrService , private service:GlobalService){
      this.titleService.setTitle('اضف مدير')  
    }
    
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة المدراء', path:'/mangers/list'} ,
      {label:'أضف مدير', active:true} 
    ]
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      password:[null,Validators.required],
      role_id:[null,Validators.required],
    })
  
   
 
    this.allRoles();
  }

  ngOnChanges() {
     this.service.setRefresh(this.data)
  }

  showpassword(type:any) {
    this.deleted_eye=! this.deleted_eye ; 
    if(type=='password') {
     this.pass_type='text'
    }
    else {
     this.pass_type='password'
    }
  }
 

  allRoles(){
    this.service.roleList().subscribe((res:any)=>{
       this.roles=res['data'] 
      // console.log("roles",this.roles)
    })
  }

  
  onChangeRole(event:any){
    this.form.value.role_id=event.value
    //console.log("role_id",this.form.value.role_id)
  }


  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
   if(this.form.invalid){ return}
    this.spinner.show();
    this.service.createAdmin(this.form.value).subscribe((res:any)=>{
      this.spinner.hide();
      this.form.reset();
      this.submitted=false;
      // console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
   }
}
