
import { Injectable } from '@angular/core';
import { Router, CanActivate} from '@angular/router';
import { AuthenticationService } from '../components/auth/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }
    canActivate() {
        if (localStorage.getItem(`${environment.currentUserKey}`)) {
          return true;
       }	
       this.router.navigate(['/']);
        return false;
         
     
      }
  //  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const currentUser = this.authenticationService.currentUserValue;
        // if (currentUser) {
        //     // check if route is restricted by role
        //     if (route.data.roles && route.data.roles.indexOf(currentUser.data.user.user_role_id) === -1) {
        //         // role not authorised so redirect to home page 
        //         this.router.navigate(['/']);
        //         return false;
        //     }
        //     // authorised so return true
        //     return true;
        // }
        // // not logged in so redirect to login page with the return url
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        // return false;
  //  }
}