import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
 
import { EditComponent } from '../edit/edit.component';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
 
// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
 

export class ListComponent implements OnInit { // ,AfterViewInit  
 
  displayedColumns: string[] = ['id', 'logoPath', 'name', 'phone', 'email' ,'balance' ,'all_movement_balance', 'files' ,'date' , 'status' , 'is_active', 'archive','settings'] ;
  dataSource:any  
 
  ///////////////////////Filter////////////////////////
 
  partners:any=[]
  data:any;
  showpartners:boolean=false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
  active:any=0
  constructor( titleService:Title, public dialog:MatDialog,public service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
    titleService.setTitle('قائمة الشركاء') 
    this.service.homeStatistics().subscribe((res:any)=>{
      //console.log("statistics", res.data)
      this.active=res.data.total_active_partners;
    })
  }  
 
  ngOnInit()  {
   this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة الشركاء', active:true} 
    ]
   
    this.pageIndex=1
    this.allPartners(1,'')
  }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    // console.log("onPaginateChange",event.pageIndex+1)   
    this.allPartners(this.pageIndex,'')
  } 
 
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  statusChange(event) {
   // console.log("event",event)
    if(event.value=='archive') {
      this.getAllArchiveUsers()
    }else {
      this.allPartners(1,'')
    }
  }
  
  allPartners(page:any,link:any){
    this.showpartners=true ;
    this.spinner.show();
    this.service.partnerList(page,link).subscribe((res:any)=>{
      this.showpartners=false ;
      this.partners=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.partners);
      this.spinner.hide();
    //  console.log("partners",this.partners)
    })
  }

  getAllArchiveUsers(){
   this.showpartners=true ;
    this.spinner.show();
    let link= this.service.changeUrlParam('is_archive',1)
    // console.log("link" ,link)
    this.service.partnerList(1,link).subscribe((res:any)=>{
      this.showpartners=false ;
      this.partners=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.partners);
      this.spinner.hide();
    //  console.log("partners",this.partners)
    })
  }

  changeActiveStatus(event,partner_id){
    // console.log("changeActiveStatus" , event?.checked)
    this.service.toggleUserStatus(partner_id).subscribe((res:any)=>{
      if(res.status==true){
        this.toast.success(res?.message ,'success') 
        this.allPartners(this.pageIndex,'')
      }
      else {
        this.toast.error(res?.message,'error');
      }
      
      },(err:any)=>{
        this.toast.error(err?.error?.message,'error');
   })
    
  }
  
  changePartnerToArchive(event,partner_id){
   // console.log("changePartnerToArchive" , event?.checked)
    this.service.toggleUserArchive(partner_id).subscribe((res:any)=>{
      if(res.status==true){
        this.toast.success(res?.message ,'success') 
        this.allPartners(this.pageIndex,'')
      }
      else {
        this.toast.error(res?.message,'error');
      }
      
      },(err:any)=>{
        this.toast.error(err?.error?.message,'error');
   })
  }

  Delete(partner_id:any){
    this.service.deletePartner(partner_id).subscribe((res:any)=>{
     this.toast.success('Success',res?.message)
     this.allPartners(this.pageIndex,'')
    },
    (err:any)=>{
    //  console.log(err.error)
     this.toast.error('Error',err?.error?.message)
    })
  }

  Edit(partner:any) {
    const dialogRef = this.dialog.open(EditComponent, {
      width: '800px',
      data: {data:partner , title:'Edit Partner'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      this.allPartners(this.pageIndex,'')
    });
  }
 
}
