import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
 
  private refresh:BehaviorSubject<any> = new BehaviorSubject<any>('');
  public getRefresh(): Observable<any> { return this.refresh.asObservable()}  //recieve
  public setRefresh(value: any):void { this.refresh.next(value) } //send
 
  constructor(private http:HttpClient , private router:Router) { }

  ////////////////download files/////////////////
  downloadFiles(id,type){
    this.http.get(`${environment.endpoint}/admin/points/export/${id}?type=${type}`, { responseType: 'blob' }).subscribe(
      (response:any) => {
          var blob = new Blob([response], { type: 'text/plain' });
          var fileURL = window.URL.createObjectURL(blob);
            saveAs(blob, `example-file.${type}`);
       },
      (e:any) => {
       console.log(e)
       }
    );
  }
 
  ///////////////////balance ///////////////////
  changeBalance(id , form){
    return this.http.post(`${environment.endpoint}/admin/users/add-balance/${id}` , form) 
  }

  // subBalance(id , form){
  //   return this.http.get(`${environment.endpoint}/admin/users/add-balance/${id}`, form) 
  // }

  ///////////////////roles//////////////////////

  roleList() {
    return this.http.get(`${environment.endpoint}/admin/roles`) 
  }

  createRole(form:any) {
    return this.http.post(`${environment.endpoint}/admin/roles/store`,form) 
  }
  
  updateRole(role_id,form:any) {
    return this.http.post(`${environment.endpoint}/admin/roles/update/${role_id}`,form)
  }

  showRoles(role_id){
    return this.http.get(`${environment.endpoint}/admin/roles/show/${role_id}`)
  }

  deleteRole(role_id) {
    return this.http.delete(`${environment.endpoint}/admin/roles/delete/${role_id}`)
  }

  ////////////////Notification///////////////////
 
  notificationList(page:any){
    return this.http.get(`${environment.endpoint}/admin/notifications?page=${page}`) 
  }

  changeNotificationStatus(id:any){
    return this.http.get(`${environment.endpoint}/admin/notifications/read/${id}`) 
  }

  /////////////////Statistics////////////////////

  homeStatistics(){
    return this.http.get(`${environment.endpoint}/admin/home`) 
  }

  userStatistics(id){
    return this.http.get(`${environment.endpoint}/admin/user/show/${id}`) 
  }

  ///////////////////Admins//////////////////////
  adminList(){
    return this.http.get(`${environment.endpoint}/admin/admins`) 
  }

  createAdmin(form:any) {
    return this.http.post(`${environment.endpoint}/admin/admin/store`,form) 
  }

  updateAdmin(admin_id,form:any) {
    return this.http.post(`${environment.endpoint}/admin/admin/update/${admin_id}`,form)
  }

  deleteAdmin(admin_id:any){
    return this.http.delete(`${environment.endpoint}/admin/admin/destroy/${admin_id}`)
  }

  ///////////////////Partners//////////////////////

  partnerList(page:any ,url:any){
    return this.http.get(`${environment.endpoint}/admin/users?page=${page}${url}`) 
  }

  createPartner(form:any) {
    return this.http.post(`${environment.endpoint}/admin/user/store`,form) 
  }

  updatePartner(form:any) {   
    return this.http.post(`${environment.endpoint}/admin/user/update/${form.id}?name=${form.name}&phone=${form.phone}&password=${form.password}&email=${form.email}`,null)
  }

  deletePartner(partner_id:any){
    return this.http.delete(`${environment.endpoint}/admin/user/destroy/${partner_id}`)
  }

  toggleUserStatus(partner_id:any){
    return this.http.get(`${environment.endpoint}/admin/users/toggle-active/${partner_id}`) 
  }

  toggleUserArchive(partner_id:any){
    return this.http.get(`${environment.endpoint}/admin/users/toggle-archive/${partner_id}`) 
  }
  ///////////////////Bank Accounts//////////////////////

  bankAccountsList(){
    return this.http.get(`${environment.endpoint}/admin/bank-accounts`) 
  }

  createBankAccount(form:any) {
    return this.http.post(`${environment.endpoint}/admin/bank-accounts/store`,form) 
  }

  getBankAccountById(bank_id:any){
    return this.http.get(`${environment.endpoint}/admin/bank-accounts/show/${bank_id}`) 
  }
  
  updateBankAccounts(form:any) {
    return this.http.post(`${environment.endpoint}/admin/bank-accounts/update/${form.id}`,form)
  }

  deleteBankAccount(bank_id:any){
    return this.http.delete(`${environment.endpoint}/admin/bank-accounts/destroy/${bank_id}`)
  }

  ///////////////////New Requests //////////////////////
  allPointsList(form:any){
    return this.http.get(`${environment.endpoint}/admin/allpoints?is_archive=${form.is_archive}&user_id=${form.user_id}`) 
  }

  allMovementList(form:any){
    return this.http.get(`${environment.endpoint}/admin/allmovements?is_archive=${form.is_archive}&user_id=${form.user_id}`) 
  }

  /////////////////////Bank-Requests//////////////////////

  allMovementRequests(page,url:any){
    return this.http.get(`${environment.endpoint}/admin/allmovements?page=${page}${url}`)
  }

  changeMovementStatus(id:any,form:any) {
    return this.http.post(`${environment.endpoint}/admin/movements/status/${id}`,form)
  }
 
  changeMovementArchive(id:any) {
    return this.http.post(`${environment.endpoint}/admin/movements/archive/${id}`,null) 
  }

  ///////////////////Point-Requests///////////////////////

  allPointsRequests(page,url:any){
    return this.http.get(`${environment.endpoint}/admin/allpoints?page=${page}${url}`)
  }

  changePointStatus(id:any , form:any) {
    return this.http.post(`${environment.endpoint}/admin/points/change-status/${id}`,form)
  }

  changePointsApproval(id:any , form:any) {
    // return this.http.get(`${environment.endpoint}/admin/points/approve/${id}`)
    return this.http.post(`${environment.endpoint}/admin/points/approve/${id}`,form)
  }

  changePointsArchive(id:any) {
    return this.http.post(`${environment.endpoint}/admin/points/archive/${id}`,null)
  }

  /////////////////////Setting/////////////////////////
  
  updateSettings(form:any) {
    return this.http.post(`${environment.endpoint}/admin/update-settings`,form) 
  }

  getSettings(){
    return this.http.get(`${environment.endpoint}/admin/settings`) 
  }

  /////////////////////Profile/////////////////////////

  updateProfile(form:any) {
    return this.http.post(`${environment.endpoint}/admin/update-profile`,form) 
  }

  updatePassword(form:any) {
    return this.http.post(`${environment.endpoint}/admin/update-password`,form) 
  }

  getProfile(){
    return this.http.get(`${environment.endpoint}/admin/my-account`) 
  }

  //////////////Create Filter Link/////////////////////
  changeUrlParam(key:any, value:any){
  
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');
    //console.log("params",params)

    return params ; 
  }
}