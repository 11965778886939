import { Component , OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
 
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent  implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  settings:any;
  data:any=[]
  constructor(titleService:Title ,private formbuilder:FormBuilder, private spinner:NgxSpinnerService ,
   private toastr:ToastrService , private service:GlobalService){
    titleService.setTitle('الإعدادات العامة')  
   }
    
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'الإعدادات العامة', active:true} 
    ]

    this.form = this.formbuilder.group({
      email:this.formbuilder.array([]), 
      notification_email:this.formbuilder.array([]), 
      phone:this.formbuilder.array([]), 
      point_per_one:[null,Validators.required],
   
   })
    
    this.getAdminData();
   
  }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  get email():any {
    return this.form.get("email") as FormArray
  }
 
  addEmail() {
    this.email.push(this.formbuilder.control(null,Validators.required))
  }
 
   removeEmail(index: any) {
   //  console.log(typeof(index)) 
     this.email.removeAt(index);
  }

  get notification_email():any {
    return this.form.get("notification_email") as FormArray
  }
 
  addNotificationEmail() {
    this.notification_email.push(this.formbuilder.control(null,Validators.required))
  }
 
   removeNotificationEmail(index: any) {
    // console.log(typeof(index)) 
     this.notification_email.removeAt(index);
  }

  getAdminData(){
    this.spinner.show();
    this.service.getSettings().subscribe((res:any)=>{
      this.spinner.hide();
      this.settings=res?.data;
     // console.log("settings",this.settings)

      let emails:any=this.settings.email.split(',')
      for(let e=0 ; e<emails.length ; e++) {
        this.email.push(this.formbuilder.control(emails[e]))
      }
     
      let notification_email:any=this.settings.notification_email.split(',')
      for(let e=0 ; e<notification_email.length ; e++) {
        this.notification_email.push(this.formbuilder.control(notification_email[e]))
      }

      let phone:any=this.settings.phone.split(',')
      for(let e=0 ; e<phone.length ; e++) {
        this.phone.push(this.formbuilder.control(phone[e]))
      }
       this.form.patchValue({
        point_per_one:this.settings.point_per_one  || null,
      })
    },(err:any)=>{
      this.spinner.hide();
    })
   
  }
 
  get phone():any {
    return this.form.get("phone") as FormArray
  }

  addPhone() {
    this.phone.push(this.formbuilder.control(null,Validators.required))
  }
 
  removePhone(index: any) {
   // console.log(typeof(index)) 
    this.phone.removeAt(index);
  }
 
  get f():any {return this.form.controls}
 
  onSubmit(){
   this.submitted=true;
  if(this.form.invalid){ return} 
  // remove any empty index
   this.form.value.email.filter((item:any) => item)
   this.form.value.notification_email.filter((item:any) => item)
   this.form.value.phone.filter((item:any) => item)

   // convert arrays to strings splited by coma
   let send_form = {
     id:this.settings.id,
     email: this.form.value.email.toString(), 
     notification_email: this.form.value.notification_email.toString(), 
     phone:this.form.value.phone.toString(), 
     point_per_one:this.form.value.point_per_one ,
   }
 
  // console.log("send_form",send_form)
    this.spinner.show();
    this.service.updateSettings(send_form).subscribe((res:any)=>{
     this.spinner.hide();
    //  console.log("success" ,res)
    // this.router.navigate(['/home'])
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
