import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
 
  permissions:any=[
              {name_ar:'المدراء' , name_en:'managers'}  ,
              {name_ar:'الشركاء' , name_en:'users'}  ,
              {name_ar:'الحسابات البنكية ' , name_en:'bank_accounts'}  ,
              {name_ar: 'الطلبات الجديدة' , name_en:'new_requests'},
              {name_ar:'الإعدادات العامة' , name_en:'settings'}  ,
              {name_ar: 'المهام' , name_en:'roles'}
            ]
 
  constructor( public dialogRef: MatDialogRef<EditComponent>,private formbuilder:FormBuilder , private spinner:NgxSpinnerService ,
    @Inject(MAT_DIALOG_DATA) public data:any , private toastr:ToastrService , private service:GlobalService){}
    
    
  ngOnInit() {
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      permissions:[null,Validators.required],
    })
  //  console.log("data",this.data)
   this.getAdminData();
  }

public permission_arr:any=[]
  getAdminData(){
    this.service.showRoles(this.data.data.id).subscribe((res:any)=>{
     //  console.log("showRoles",res.data)
      res?.data?.permissions.forEach(permission => {
      this.permission_arr.push(permission?.role)   
      });

      this.form.patchValue({
        name:res.data.name || null,
        permissions: this.permission_arr
      })
     // console.log("form",this.form.value)
    })
  }

  compareCompatibles(optionOne,optionTwo){
    if(optionOne.name_ar==optionTwo) {
      console.log("optionOne ",optionOne , optionTwo )
    }
    console.log("testttt ",optionOne , optionTwo )
   return optionOne.name_en == optionTwo 
  }

  onChangePermissions(event:any){
    let arr =[]
   event.value.forEach(element => {
    arr.push(element.name_en)
   });
   this.form.value.permissions=arr
  // console.log("onChangePermissions",this.form.value.permissions)
  }

  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
    if(this.form.invalid){ return}
    this.spinner.show();
    this.service.updateRole(this.data.data.id,this.form.value).subscribe((res:any)=>{
   //   console.log("success" ,res)
      this.spinner.hide();
      this.dialogRef.close();
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
   }
}
