import { Component ,OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  data:any=[]
  permissions:any=[
    {name_ar:'المدراء' , name_en:'managers'}  ,
    {name_ar:'الشركاء' , name_en:'users'}  ,
    {name_ar:'الحسابات البنكية ' , name_en:'bank_accounts'}  ,
    {name_ar: 'الطلبات الجديدة' , name_en:'new_requests'},
    {name_ar:'الإعدادات العامة' , name_en:'settings'}  ,
    {name_ar: 'المهام' , name_en:'roles'}
  ]
  
  constructor(titleService:Title ,private formbuilder:FormBuilder , private spinner:NgxSpinnerService ,
    private toastr:ToastrService , private service:GlobalService){
      titleService.setTitle('اضف مهمة')  
    }
    
  ngOnInit() {
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      permissions:[null,Validators.required],
    })

    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة المهام', path:'/roles/list'} ,
      {label:'أضف مهمة', active:true} 
    ]
   
  }
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  
 onChangePermissions(event:any){
   this.form.value.permissions=event.value
  //console.log("events",this.form.value.permissions )
}
 
  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
   if(this.form.invalid){ return}
   this.spinner.show();
    this.service.createRole(this.form.value).subscribe((res:any)=>{
      this.spinner.hide();
      this.form.reset();
      this.submitted=false;
      // console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
   }
}
