import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
// import { environment } from 'src/environments/environment';
import { RefuseRequestComponent } from '../refuse-request/refuse-request.component';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
// import * as saveAs from 'file-saver';
 

@Component({
  selector: 'app-point-requests',
  templateUrl: './point-requests.component.html',
  styleUrls: ['./point-requests.component.scss']
}) 
export class PointRequestsComponent implements OnInit { // ,AfterViewInit  
  public pointssss:any ;
  displayedColumns: string[] = ['id', 'created_at', 'name','benifit_num','points_num'  ,'approved_at' ,'approval_by','status','execute'   ,'settings'] ;
  dataSource:any  
  @Input('user_statistics_id') user_statistics_id:any;
  ///////////////////////Filter////////////////////////
  user_id:any = this.route.snapshot.paramMap.get('id');
  requests:any=[]
  data:any;
  showrequests:boolean=false;
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
  // environment=environment.files_end_point
   environment=environment.endpoint
  constructor(private http:HttpClient ,public route :ActivatedRoute ,public titleService:Title, public dialog:MatDialog,public service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
   // console.log("this.user_statistics_id",this.user_statistics_id)
   this.titleService.setTitle(this.user_id?'الإحصائيات':'قائمة طلبات النقاط')  
  }  
 
  ngOnInit()  {
    this.pageIndex=1
    
    if(this.user_id) {
      this.getAllUserStatistics(this.pageIndex)
      // this.titleService.setTitle('الإحصائيات') 
      this.data=[
        {label:'الصفحة الرئيسية', path:'/home'},
        {label:'قائمة الشركاء', path:'/partner/list'},
        {label:'الإحصائيات', active:true} 
      ]
      //  this.service.setRefresh(this.data)
    }else {
    // this.titleService.setTitle('قائمة طلبات النقاط')  
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة طلبات النقاط', active:true} 
    ]
    // this.service.setRefresh(this.data)
    this.allRequests(this.pageIndex,'');
    }
  }

  // ngAfterViewInit() {
    
  // }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  
  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    if(this.user_statistics_id) {
      this.getAllUserStatistics(this.pageIndex)
    }else {
      this.allRequests(this.pageIndex ,'');
    }
  } 

  getAllUserStatistics(page:any){
   // alert('getAllUserStatistics')
    this.showrequests=true ;
    this.spinner.show();
    let link= this.service.changeUrlParam('user_id',this.user_statistics_id)
    // console.log("link" ,link)
    this.service.allPointsRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
     // console.log("requests",this.requests)
    })
  }

  allRequests(page:any,link:any){
    this.showrequests=true ;
    this.spinner.show();
    this.service.allPointsRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
     //console.clear()
    // console.log("requests",this.requests)
    })
  }
   
  getAllArchivePoints() {
   let link= this.service.changeUrlParam('is_archive',1)
    // console.log("link" ,link)
    this.showrequests=true ;
    this.spinner.show();
    this.service.allPointsRequests(1,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
     // console.clear()
    //  console.log("requests",this.requests)
    })
  }

  statusChange(event){
    //console.log("event",event)
    if(event.value=='archive') {
      this.paginator.firstPage()
      this.getAllArchivePoints()
    }else {
      this.paginator.firstPage()
      if(this.user_statistics_id) {
        this.getAllUserStatistics(this.pageIndex)
      }else {
        this.allRequests(this.pageIndex ,'');
      }
    }
  }
  
  changepointApproval(id:any , status:any){
    let form = {
      status :status
    }
    if(status!=3) {
      this.service.changePointsApproval(id , form).subscribe((res:any)=>{
       // console.log("success" ,res)
        if(res.status==true){
          this.toast.success(res?.message ,'success') 
          if(this.user_statistics_id) {
            this.getAllUserStatistics(this.pageIndex)
          }else {
            this.allRequests(this.pageIndex ,'');
          }
        }
        else {
          this.toast.error(res?.message,'error');
        }
        
        },(err:any)=>{
          this.toast.error(err?.error?.message,'error');
      })
    }else {
      this.rejectmovementStatus(id) 
    }
  }
 
  changepointStatus(id:any , status:any){ 
    let form ={
      status:status
    }
    this.service.changePointStatus(id , form ).subscribe((res:any)=>{
      // console.log("success" ,res)
       if(res.status==true){
          this.toast.success(res?.message ,'success') 
          if(this.user_statistics_id) {
            this.getAllUserStatistics(this.pageIndex)
          }else {
            this.allRequests(this.pageIndex ,'');
          }
        }
        else {
          this.toast.error(res?.message,'error');
        }
        
        },(err:any)=>{
          this.toast.error(err?.error?.message,'error');
     })
  }

  rejectmovementStatus(id) {
    const dialogRef = this.dialog.open(RefuseRequestComponent, {
      width: '800px',
      data: {data:{id:id , from:'points'} , title:'Refuse Request'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      if(this.user_statistics_id) {
        this.getAllUserStatistics(this.pageIndex)
      }else {
        this.allRequests(this.pageIndex ,'');
      }
    });
  }

  archivePoint(id:any){
    this.service.changePointsArchive(id).subscribe((res:any)=>{
     // console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         if(this.user_statistics_id) {
          this.getAllUserStatistics(this.pageIndex)
        }else {
          this.allRequests(this.pageIndex ,'');
        }
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
 
  
}
