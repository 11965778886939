import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent  implements OnInit{
  form!:FormGroup;
  submitted:boolean=false ; 
 
  type:any=[{name_en:'add' , name_ar:'إضافة'}, {name_en:'sub' , name_ar:'خصم'}]
  constructor(public dialogRef: MatDialogRef<BalanceComponent>,private formbuilder:FormBuilder, private spinner:NgxSpinnerService ,
    @Inject(MAT_DIALOG_DATA) public data:any , private toastr:ToastrService , private service:GlobalService){}

  ngOnInit() {
    this.form = this.formbuilder.group({
      amount:[null,Validators.required],
      type:[null,Validators.required],
    })
    //console.log("data",this.data.data)
  }
 
  
  onChangeRole(event:any){
    this.form.value.type=event.value
   // console.log("type",this.form.value.type)
   }
  

  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
   if(this.form.invalid){ return}
   //console.log("form",this.form.value)
   this.spinner.show()
    this.service.changeBalance(this.data.data ,this.form.value).subscribe((res:any)=>{
    //  console.log("success" ,res)
      this.spinner.hide();
      this.toastr.success(res?.message ,'success') 
      this.dialogRef.close();
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
  
   }
}
