import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  items:any = []
  @Input('crumbData') crumbData:any=[];
  constructor(){

  }
  ngOnInit(): void {
   
  } 

  ngAfterViewInit() {
    this.items=this.crumbData
  }
  
  
}
