import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';  
import { ListComponent } from './list/list.component';
import { BankTransferComponent } from './bank-transfer/bank-transfer.component';
import { PointRequestsComponent } from './point-requests/point-requests.component';
 
const routes: Routes = [
    { path:'bank', component: BankTransferComponent} ,
    { path:'points', component: PointRequestsComponent} ,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewRequestsRoutingModule { }
