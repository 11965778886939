import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsRoutingModule } from './general-settings-routing.modules';
import { SettingComponent } from './setting/setting.component';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
 // import { TranslateModule } from '@ngx-translate/core'; 
 
@NgModule({
  declarations: [SettingComponent], 
  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,   
    MatIconModule,
    SharedModule,
    
  ]
})
export class SettingsModule { }
