import { Component, Input, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { RefuseRequestComponent } from '../refuse-request/refuse-request.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss']
})
export class BankTransferComponent implements OnInit { // ,AfterViewInit  
  displayedColumns: string[] = ['id','created_at', 'name','amount', 'status' ,'reason', 'approved_at' ,'approval_by' ,'logoPath' ,'settings'] ;
  dataSource:any  
  @Input('user_statistics_id') user_statistics_id:any;
  ///////////////////////Filter////////////////////////
  user_id:any = this.route.snapshot.paramMap.get('id');
  requests:any=[]
  data:any;
  showrequests:boolean=false;
  @ViewChild('paginator') paginator!: MatPaginator;
  @Input() pageIndex: number;
  total:any=100;
  environment=environment.files_end_point
  constructor(public titleService:Title, public route :ActivatedRoute, public dialog:MatDialog,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
    this.titleService.setTitle(this.user_id?'الإحصائيات':'قائمة التحويلات البنكية') 
  }  
 
  ngOnInit()  {
    this.pageIndex=1;

    if(this.user_id) {
      this.getAllUserStatistics(this.pageIndex)
    // this.titleService.setTitle('الإحصائيات') 
      this.data=[
        {label:'الصفحة الرئيسية', path:'/home'},
        {label:'قائمة الشركاء', path:'/partner/list'},
        {label:'الإحصائيات', active:true} 
      ]
      // this.service.setRefresh(this.data)
    }else {
      // this.titleService.setTitle('قائمة التحويلات البنكية') 
      this.data=[
        {label:'الصفحة الرئيسية', path:'/home'},
        {label:'قائمة التحويلات البنكية', active:true} 
      ]
      // this.service.setRefresh(this.data)
      this.allRequests(this.pageIndex ,'');
    }
  }

  // ngAfterViewInit() { 
   
  // }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  onPaginateChange(event){
    this.pageIndex=event.pageIndex+1
    // console.log("pageIndex",this.pageIndex)
    // this.allRequests(this.pageIndex,'')  
    if(this.user_statistics_id) {
      this.getAllUserStatistics(this.pageIndex)
    }else {
      this.allRequests(this.pageIndex ,'');
    }

  } 
 
  getAllUserStatistics(page:any){
   // alert('getAllUserStatistics')
    this.showrequests=true ;
    this.spinner.show();
    let link= this.service.changeUrlParam('user_id',this.user_statistics_id)
    // console.log("link" ,link)
    this.service.allMovementRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
      // console.log("getAllUserStatistics",res.data)
    })
  }

  allRequests(page:any,link:any){
    //alert('allRequests')
    this.showrequests=true ;
    this.spinner.show();
    // let link= this.service.changeUrlParam('user_id',1)
    // console.log("link" ,link)
    this.service.allMovementRequests(page,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
      //console.log("allRequests",res.data)
    })
  }

  getAllArchiveMovements(){
   // alert('getAllArchiveMovements')
    this.showrequests=true ;
    this.spinner.show();
    let link= this.service.changeUrlParam('is_archive',1)
    // console.log("link" ,link)
    this.service.allMovementRequests(1,link).subscribe((res:any)=>{
      this.showrequests=false ;
      this.requests=res.data['data']
      this.total=res.data.total
      this.dataSource= new MatTableDataSource(this.requests);
      this.spinner.hide();
      // console.log("requests",this.requests)
    })
  }

  statusChange(event){
    //console.log("event",event)
    if(event.value=='archive') {
      this.paginator.firstPage()
      this.getAllArchiveMovements()
    }else {
      this.paginator.firstPage()
      if(this.user_statistics_id) {
        this.getAllUserStatistics(this.pageIndex)
      }else {
        this.allRequests(this.pageIndex ,'');
      }
    }
  }
 
  acceptmovementStatus(id) {
  let form = {
    status:1
  }
    this.service.changeMovementStatus(id,form).subscribe((res:any)=>{
     // console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         if(this.user_statistics_id) {
          this.getAllUserStatistics(this.pageIndex)
        }else {
          this.allRequests(this.pageIndex ,'');
        }
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }

  rejectmovementStatus(id) {
    const dialogRef = this.dialog.open(RefuseRequestComponent, {
      width: '800px',
      data: {data:{id:id , from:'transfer'} , title:'Refuse Request'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      if(this.user_statistics_id) {
        this.getAllUserStatistics(this.pageIndex)
      }else {
        this.allRequests(this.pageIndex ,'');
      }
    });
  }
     
  archiveMovement(id) {
    this.service.changeMovementArchive(id).subscribe((res:any)=>{
    //  console.log("success" ,res)
       if(res.status==true){
         this.toast.success(res?.message ,'success') 
         if(this.user_statistics_id) {
          this.getAllUserStatistics(this.pageIndex)
        }else {
          this.allRequests(this.pageIndex ,'');
        }
       }
       else {
         this.toast.error(res?.message,'error');
       }
       
       },(err:any)=>{
         this.toast.error(err?.error?.message,'error');
    })
  }
  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
