import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  constructor(public dialogRef: MatDialogRef<EditComponent>,private formbuilder:FormBuilder, private spinner:NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data:any , private toastr:ToastrService , private service:GlobalService){}

    ngOnInit() {
      this.form = this.formbuilder.group({
        bank_name:[null,Validators.required],
        bank_number:[null,Validators.required],
        bank_owner:[null,Validators.required],
      })
      console.log("data",this.data)
     this.getAdminData()
    }
   
    getAdminData(){
      this.form.patchValue({
        bank_name:this.data.data.bank_name || null,
        bank_number:this.data.data.bank_number || null,
        bank_owner:this.data.data.bank_owner || null,
      })
    }

    get f():any {return this.form.controls}
  
    onSubmit(){
      this.submitted=true;
    //  if(this.form.invalid){ return}
     let send_form={
      id:this.data.data.id,
      ...this.form.value
     }

      this.spinner.show();
      this.service.updateBankAccounts(send_form).subscribe((res:any)=>{
        this.spinner.hide();
        // console.log("success" ,res)
        this.toastr.success(res?.message ,'success') 
        this.dialogRef.close()
      },(err:any)=>{
        this.spinner.hide();
        this.toastr.error(err?.error?.message,'error');
      })
     }
}
