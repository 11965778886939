import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import * as echarts from 'echarts';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
     usersStatistics:any ={} 
     pointsStatistics:any ={}
     empsStatistics:any={}
     data:any=[] 
     statistics:any;  
    
     statistics_cards:any= 
     [   
       {id:0 , name:'عدد المستخدمين داخل لوحة المدراء' , icon:'assets/images/users.svg',  hexcode:'#4831d4',  value:' ' , path:'/mangers/list'},
       {id:1 , name:'عدد الشركاء المعتمدين' , icon:'assets/images/users.svg' , hexcode:'#CFB0ED', value:'' , path:'/partner/list'},
       {id:2 , name:'عدد النقاط الموردة آخر شهر', icon:'assets/images/calendar.svg'  ,  hexcode:'#ea2087',  value:'' , path:'/new-requests/points'},
       {id:3 , name:'عدد النقاط الموردة سنويا' , icon:'assets/images/dollar-sign.svg',  hexcode:'#F8B271',  value:'' ,  path:'/new-requests/points'},
       {id:4 , name:'عدد المستفيدين آخر شهر من موظفي الشركات الكلي', icon:'assets/images/user-plus.svg', hexcode:'#8AD8CA',  value:'' , path:'/new-requests/points'},
       {id:5 , name:'عدد المستفيدين سنويا من موظفي الشركات الكلي' , icon:'assets/images/user-plus.svg', hexcode:'#9AC3FF',  value:'' , path:'/new-requests/points'},
      
     ]
  constructor(public service:GlobalService,titleService:Title){
    titleService.setTitle('الصفحة الرئيسية') 
    this.data=[
      {label:''},
  ]
   
    this.service.homeStatistics().subscribe((res:any)=>{
      this.statistics=res.data
      // console.log("statistics", res.data)
      this.statistics_cards[0].value=this.statistics?.total_admins;
      this.statistics_cards[1].value=this.statistics?.total_active_partners;
      this.statistics_cards[2].value=this.statistics?.last_month_points;
      this.statistics_cards[3].value=this.statistics?.last_year_points;
      this.statistics_cards[4].value=this.statistics?.last_month_count;
      this.statistics_cards[5].value=this.statistics?.last_year_count;
     
     this.usersStatistics =this.drawPieChart([this.statistics_cards[1].name ,this.statistics_cards[0].name] ,'إحصائيات الشركاء' , [this.statistics_cards[1],this.statistics_cards[0]] ,['#4831d4', '#cfb0ed'])
     this.pointsStatistics =this.drawPieChart([this.statistics_cards[2].name ,this.statistics_cards[3].name] ,'إحصائيات النقاط المورده' , [this.statistics_cards[2],this.statistics_cards[3]] ,['#ea2087','#f8b271'])
     this.empsStatistics =this.drawPieChart([this.statistics_cards[4].name ,this.statistics_cards[5].name] ,'إحصائيات عدد المستفيدين من موظفي الشركات الكلي' , [this.statistics_cards[4],this.statistics_cards[5]] ,['#8ad8ca','#9ac3ff'])
   })
  } 

 
  ngOnInit() {
  
  }
  
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  
  newShade(hex:any){
    let c:any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.09)';
    }
     throw new Error('Bad Hex');
  }

  drawPieChart( title_arr , pie_title , pie_data , pie_colors){
    let option :any = {
      
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} {c} ({d}%) '
    },
    
    legend: {
        orient: 'vertical',
        left: 10,
        data:title_arr
    },
    series: [
        {
            name: pie_title,
            type: 'pie',
            color: pie_colors, 
            radius: ['50%', '70%'],
            // radius: '60%',
            position: 'center',
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: '20',
                    fontWeight: 'bold',
                    position: 'center'
                } 
            },
            labelLine: {
                show: true
            },
            data: pie_data
        }
    ]
    }
    return option ; 
  }
  
}
