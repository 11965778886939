import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
 
 
@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  data:any=[] ;

 constructor(titleService:Title ,private formbuilder:FormBuilder,private spinner:NgxSpinnerService ,
    private toastr:ToastrService , private service:GlobalService){
      titleService.setTitle('اضف حساب بنكي')  
 
    }
    
  ngOnInit() {
    this.form = this.formbuilder.group({
      bank_name:[null,Validators.required],
      bank_number:[null,Validators.required],
      bank_owner:[null,Validators.required],
    })

    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة الحسابات البنكية', path:'/bank-accounts/list'} ,
      {label:'أضف حساب بنكي', active:true} 
    ]
    
  }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
 
  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
   if(this.form.invalid){ return}
    this.spinner.show()
    this.service.createBankAccount(this.form.value).subscribe((res:any)=>{
      this.spinner.hide();
      this.form.reset();
      this.submitted=false;
      // console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
   }
}
