import { Component,OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  data:any=[]
  constructor(titleService:Title , private formbuilder:FormBuilder,private toastr:ToastrService , private service:GlobalService , private spinner:NgxSpinnerService){
    titleService.setTitle('تعديل بيانات الملف الشخصي')  
  }
    
  ngOnInit() {
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'تعديل بيانات الملف الشخصي', active:true} 
    ]

    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required] 
    })
 
    this.getAdminData();
   }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  getAdminData(){
    this.service.getProfile().subscribe((res:any)=>{
    //  console.log("getProfile", res.data)
      this.form.patchValue({
        name:res.data.name || null,
        phone: res.data.phone || null,
        email:res.data.email || null,
      })
    })
    
  }
 
  get f():any {return this.form.controls}

  onSubmit(){
   this.submitted=true;
   if(this.form.invalid){ return}
    this.spinner.show();
    this.service.updateProfile(this.form.value).subscribe((res:any)=>{
      this.spinner.hide();
    //  console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
   },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
