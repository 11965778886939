import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NewRequestsRoutingModule } from './new-requests-routing.modules';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ListComponent } from './list/list.component';
import { BankTransferComponent } from './bank-transfer/bank-transfer.component';
import { PointRequestsComponent } from './point-requests/point-requests.component';
import { MatButtonModule } from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import { RefuseRequestComponent } from './refuse-request/refuse-request.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
@NgModule({
  declarations:[ListComponent, BankTransferComponent ,PointRequestsComponent ,RefuseRequestComponent], 
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,  
    NewRequestsRoutingModule, 
    MatIconModule,
    SharedModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatSlideToggleModule,

   MatButtonModule , 
   MatDividerModule, 
   MatRadioModule,
   MatMenuModule
   ],
   exports: [ BankTransferComponent , PointRequestsComponent]
})
export class NewRequestsModule { }
