import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
// import { NgSelectModule } from '@ng-select/ng-select';
 
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RolesRoutingModule } from './roles-routing.modules';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
 // import { TranslateModule } from '@ngx-translate/core'; 
@NgModule({
  declarations: [ListComponent,AddComponent,EditComponent ], 
  imports: [
    CommonModule,
    RolesRoutingModule,
    FormsModule,
    ReactiveFormsModule,   
    MatIconModule,
    SharedModule,
    MatDialogModule,
    MatSelectModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule
   ]
})
export class RolesModule { }
