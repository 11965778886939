import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import * as echarts from 'echarts';
import { MatDialog } from '@angular/material/dialog';
import { BalanceComponent } from '../balance/balance.component';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  pointChartOptions: any = {
    
  };
  movementChartOptions: any = {
    
  };

  form!:FormGroup;
  toggle:boolean=true;
  data:any=[]
  panelOpenState = false;
  statistics:any;
  statistics_cards:any= 
  [  
    {id:0 , title:'عدد المستفيدين الكلي ' , icon:'assets/images/user-plus.svg' ,  hexcode:'#4831d4',  value:' ' },
    {id:1 , title:'عدد المستفيدين آخر 30 يوم ' , icon:'assets/images/calendar.svg' , hexcode:'#CFB0ED', value:'' },
    {id:2 , title:'آخر رصيد تم إضافته' , icon:'assets/images/dollar-sign.svg',  hexcode:'#ea2087',  value:'' },
    {id:3 , title:'المتبقي من الرصيد الكلي' , icon:'assets/images/briefcase.svg',  hexcode:'#F8B271',  value:'' },
    {id:4 , title:'نوع المستفيدين' , icon:'assets/images/users.svg' , hexcode:'#8AD8CA',  value:'' },
    {id:5 , title:'إجمالي النقاط المصروفة' , icon:'assets/images/clipboard.svg', hexcode:'#9ac3ff',  value:'' },
    {id:6 , title:'عدد النقاط المتبقي ' , icon:'assets/images/clipboard.svg', hexcode:'#9ac3ff',  value:'' },
    {id:7, title:'إجمالي النقاط التي تساوي الرصيد المضاف' , icon:'assets/images/clipboard.svg', hexcode:'#ea2087',  value:'' },
 
    // {id:5 , title:' الرصيد المضاف' , icon:' ', hexcode:'#9AC3FF',  value:'' },
  ]
  submitted:boolean=false;
  user_id:any = this.route.snapshot.paramMap.get('id');
  environment=environment.files_end_point;
 
  constructor( private spinner:NgxSpinnerService ,private formbuilder:FormBuilder, public titleService:Title, public service:GlobalService , public route:ActivatedRoute , private toast:ToastrService ,  public dialog:MatDialog) { 
    this.getStatistics()
     
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      // point_per_one:[null,Validators.required],
      password:[null,Validators.required]
    })
    
  //  console.log("user_id",this.user_id)   
   
  }

  ngOnInit(): void {
 
  }
 
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
  
  changeBalanceManual() {
    const dialogRef = this.dialog.open(BalanceComponent, {
      width: '800px',
      data: {data:this.user_id, title:'change balance '},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getStatistics();
    });
  }

  getStatistics(){
    this.service.userStatistics(this.user_id).subscribe((res:any)=>{
      this.statistics=res
    //  console.log("statistics", this.statistics  )
      this.statistics_cards[0].value=res?.statistics?.total_count || '-'  ;
      this.statistics_cards[1].value=res?.statistics?.last_month || '-'  ;
      this.statistics_cards[2].value=res?.data?.lastBalanceAdded || '-'  ;
      this.statistics_cards[3].value= res?.data?.balance || '-'  ;
      this.statistics_cards[4].value={male:res?.statistics?.males , female: res?.statistics?.females };
      this.statistics_cards[5].value=res?.statistics?.total_points || '-'  ;
      this.statistics_cards[6].value=res?.statistics?.remain_points || '-'  ;
      this.statistics_cards[7].value=res?.statistics?.edited_balance_pointe || '-'  ;
       
      this.form.patchValue({
        name:res?.data?.name , 
        phone:res?.data?.phone,
        email:res?.data?.email,
        // point_per_one:res?.statistics?.total_points
      })
  
     if(res?.statistics?.monthly_points.length!=0){
       this.pointChartOptions= this.createChart(res?.statistics?.monthly_points , 'bar')
     }
    
     if(res?.statistics?.monthly_movements.length!=0){
      // let arr = [{monthyear: '1-2023' , data:'1000'} , {monthyear: '12-2023' , data:'1500'} ]
      this.movementChartOptions= this.createChart(res?.statistics?.monthly_movements , 'bar')
      // this.movementChartOptions= this.createChart(arr , 'line')
    }
      
   
    //  let arr = [{monthyear: '1-2023' , data:'1000'} , {monthyear: '5-2023' , data:'1500'} ]
    // res?.statistics?.monthly_points.forEach((elem:any)=>{
    //   let index =elem.monthyear.split('-')
    //    this.points_chart_data[index[0]-1]=elem.data
      
    // })
    // this.points_chart_data= Array.from(this.points_chart_data, item => item || 0)
    // console.log("result",this.points_chart_data)
    // this.chartOption = {
    //   xAxis: {
    //     type: 'category',
    //     data: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو' ,'أغسطس' ,'سبتمبر' ,'أكتوبر' ,'نوفمبر','ديسمبر'],
    //  },
    //   yAxis: {
    //     type: 'value',
    //   },
    //   series: [
    //     {
    //       data: this.points_chart_data, 
    //       type: 'line', //bar
    //     },
    //   ],
    // };
  
 
    })
    
  }

  createChart(arrOfObj:any , chartType:any){
    let chartData:any=[];
    let chart:any=[] ;
    chartData.length=12;
    arrOfObj.forEach((elem:any)=>{
      let index =elem.monthyear.split('-')
      chartData[Number(index[0])-1]=elem.data
      
    })
    chartData= Array.from(chartData, item => item || 0)
    // console.log("result",chartData)
    // let chartOption = {
    //   xAxis: {
    //     type: 'category',
    //     data: [ 'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو' ,'أغسطس' ,'سبتمبر' ,'أكتوبر' ,'نوفمبر','ديسمبر'],
    //  },
    //   yAxis: {
    //     type: 'value',
    //   },
    //   series: [
    //     {
    //       data: chartData, 
    //       type:chartType,  
    //     },
    //   ],
    // };
 
    
    chartData.forEach((data:any) => {
    chart.push({value:data , itemStyle: { color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#ea208726'},
                                {offset: 0.5, color:'#4831d4'},
                                {offset: 1, color: '#4831d4'}
                            ]
                          )}, 
                        })
    });
    let chartOption = {
      xAxis: {
        type: 'category',
        data: [ 'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو' ,'أغسطس' ,'سبتمبر' ,'أكتوبر' ,'نوفمبر','ديسمبر'],
     },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          barWidth: '40%',
          data: chart, 
          type:chartType,  
        },
      ],
    };

   return chartOption;
  //   let chartOption = {
  //     color: ['#007F7A'],
  //     tooltip: {
  //         trigger: 'axis',
  //         axisPointer: {            
  //             type: 'shadow'        //onHover：'line' | 'shadow'
  //         }
  //     },
  //     grid: {
  //         left: '3%',
  //         right: '4%',
  //         bottom: '3%',
  //         containLabel: true
  //     },
  //     xAxis: [
  //         {
  //             type: 'category',
  //             data: [ 'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو' ,'أغسطس' ,'سبتمبر' ,'أكتوبر' ,'نوفمبر','ديسمبر'],
  //             axisTick: {
  //                 alignWithLabel: true
  //             }
  //         }
  //     ],
  //     yAxis: [
  //         {
  //             type: 'value'
  //         }
  //     ],
  //     series: [
  //         {
  //             name: 'value',
  //             type: 'bar',
  //             barWidth: '40%',
  //             data: [ 
  //               {
  //                   value: 120,
  //                   itemStyle: { color: new echarts.graphic.LinearGradient(
  //                     0, 0, 0, 1,
  //                     [
  //                         {offset: 0, color: '#ea208726'},
  //                         {offset: 0.5, color:'#4831d4'},
  //                         {offset: 1, color: '#4831d4'}
  //                     ])},
  //               },
  //               {
  //                   value: 200,
  //                   itemStyle: { color: new echarts.graphic.LinearGradient(
  //                     0, 0, 0, 1,
  //                     [
  //                       {offset: 0, color: '#ea208726'},
  //                       {offset: 0.5, color:'#4831d4'},
  //                       {offset: 1, color: '#4831d4'}
  //                     ])},
  //               },
  //               {
  //                   value: 150,
  //                   itemStyle: { color: new echarts.graphic.LinearGradient(
  //                     0, 0, 0, 1,
  //                     [
  //                       {offset: 0, color: '#ea208726'},
  //                         {offset: 0.5, color:'#4831d4'},
  //                         {offset: 1, color: '#4831d4'}
  //                     ])},
  //               }
  //             ]  
  //         }
  //     ]
  // };
  //return chartOption;
  }


  image_exist:boolean =false ;
  // uploded_img:any=[]
  // public imagePath :File[]= [];
  // images : string[] = [];
  // file_names:any=[]

  // onFileChange(event:any) {
  //   this.image_exist =true ;
  //    this.uploded_img=event.target.files[0]
  //   if (event.target.files && event.target.files[0]) {
  //        var filesAmount = event.target.files.length;
  //        for (let i = 0; i < filesAmount; i++) {
  //             var reader = new FileReader();
  //             this.imagePath.push(...event.target.files)
  //             reader.onload = (event:any) => {
  //               this.images.push(event.target.result); 
  //             }
  //          reader.readAsDataURL(event.target.files[i]);
  //        }
  //    }
  //    console.log("form", this.uploded_img)
  // }

  newShade(hex:any){
    let c:any;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.09)';
    }
     throw new Error('Bad Hex');
  }

  get f():any {return this.form.controls}

  onSubmit(){
    // delete this.form.value.point_per_one
   // console.log("ddd",this.form.value)
    this.submitted=true;
   //  if(this.form.invalid){ return}
    let sent_form ={
      id : this.user_id ,
      ...this.form.value
    }
    this.spinner.show();
    this.service.updatePartner(sent_form).subscribe((res:any)=>{
    //  console.log("success" ,res)
      this.spinner.hide();
      this.toast.success(res?.message ,'success') 
      this.getStatistics();
    },(err:any)=>{
      this.spinner.hide();
      this.toast.error(err?.error?.message,'error');
    })
  }
}
