import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit{
  form!:FormGroup;
  submitted:boolean=false ; 
  deleted_eye:boolean=false;
  pass_type:any='password' ;
 
  constructor(public dialogRef: MatDialogRef<EditComponent>,private formbuilder:FormBuilder, private spinner:NgxSpinnerService ,
    @Inject(MAT_DIALOG_DATA) public data:any , private toastr:ToastrService , private service:GlobalService){}

  ngOnInit() {
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      password:[null,Validators.required],
    })
   // console.log("data",this.data.data)
    this.getAdminData() ;
  }

  showpassword(type:any) {
    this.deleted_eye=! this.deleted_eye ; 
    if(type=='password') {
     this.pass_type='text'
    }
    else {
     this.pass_type='password'
    }
  }

  getAdminData(){
    this.form.patchValue({
      name:this.data.data.name || null,
      phone:this.data.data.phone || null,
      email:this.data.data.email || null,
    })
  }

  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
  //  if(this.form.invalid){ return}
   let sent_form ={
    id : this.data.data.id ,
    ...this.form.value
   }
    this.spinner.show()
    this.service.updatePartner(sent_form).subscribe((res:any)=>{
   //   console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
      this.dialogRef.close();
      this.spinner.hide()
    },(err:any)=>{
      this.spinner.hide()
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
