import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  deleted_eye:boolean=false; //confirm_deleted_eye:boolean=false ;
  pass_type:any='password' ;// confirm_pass_type:any='password';
  roles:any=[]
 
  constructor(public dialogRef: MatDialogRef<EditComponent>,private formbuilder:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data:any , private toastr:ToastrService , private service:GlobalService , private spinner:NgxSpinnerService){}
    
  ngOnInit() {
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      password:[null,Validators.required],
      role_id:[null,Validators.required],
   })

    //console.log("data", this.data)
    this.getAdminData();
    this.allRoles();
  }

  

  allRoles(){
    this.service.roleList().subscribe((res:any)=>{
       this.roles=res['data'] 
      //  console.log("roles",this.roles)
    })
  }

  getAdminData(){
     
    this.form.patchValue({
      name:this.data?.data?.name || null,
      phone:this.data?.data?.phone || null,
      email:this.data?.data?.email || null,
      role_id:this.data?.data?.role?.id || null,
   })
  }
 
  compareCompatibles(optionOne,optionTwo){
     // console.log("optionOne,optionTwo",optionOne,optionTwo )
      return optionOne ==optionTwo 
  }

  onChangeRole(event:any){
    this.form.value.role_id=event.value
    // console.log("role_id",this.form.value.role_id)
  }
  
  showpassword(type:any) {
    this.deleted_eye=! this.deleted_eye ; 
    if(type=='password') {
     this.pass_type='text'
    }
    else {
     this.pass_type='password'
    }
  }

  // showconfirmpassword(type:any) {
  //   this.confirm_deleted_eye=! this.confirm_deleted_eye ; 
  //   if(type=='password') {
  //   this.confirm_pass_type='text'
  //   }
  //   else {
  //   this.confirm_pass_type='password'
  //   }
  // } 

  get f():any {return this.form.controls}

  onSubmit(){
   this.submitted=true;
   // console.log("send_form",this.form.value)
    this.spinner.show();
    this.service.updateAdmin(this.data.data.id,this.form.value ).subscribe((res:any)=>{
      this.spinner.hide();
    //  console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
      this.dialogRef.close();
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
  }
}
