import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
 
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent  implements OnInit { // ,AfterViewInit  
  data:any=[]
  bank:boolean=true;
  constructor(titleService:Title ,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
    titleService.setTitle('قائمة الطلبات الجديدة') 
  }  
 
  ngOnInit()  {
   this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة الطلبات الجديدة', active:true} 
    ]
  }
  
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }
}
