import { Component, OnInit } from '@angular/core';
import {  NavigationEnd, Router } from '@angular/router';
 
import { Title } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
 
@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
 
  public pageTitle;
  public breadCrumbData:any=[];
  constructor(titleService:Title, router:Router ,private service:GlobalService) {

    this.service.getRefresh().subscribe((res:any)=>{
      this.breadCrumbData=res
    })

    router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        let title = this.getTitle(router.routerState, router.routerState.root).join('-');
        this.pageTitle = titleService.getTitle()
        // change page title in browser
        // document.title = `Ekram Admin ${title}`
       
        }
    });
    
    // this.service.getRefresh().subscribe((res:any)=>{
    //   this.breadCrumbData=res
    //   // console.log(this.breadCrumbData)
    //  })
  }

  ngOnInit(): void {
   
  }

 
  ngAfterViewInit() {
   
  }

  getTitle(state, parent) {
    var data = [];
    if(parent && parent.snapshot.data && parent.snapshot.data.title) { data.push(parent.snapshot.data.title)}
    if(state && parent) {data.push(... this.getTitle(state, state.firstChild(parent)))}
    return data;
  }

}
