import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditComponent } from '../edit/edit.component';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
// import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  admins:any=[]
  data:any;
  showadmins:boolean=false;
 
  constructor(private titleService:Title, public dialog:MatDialog,private service:GlobalService , private toast:ToastrService , private spinner:NgxSpinnerService) {
    this.titleService.setTitle('قائمة المدراء') 
  } 
  
  ngOnInit()  {
  
    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة المدراء', active:true} 
    ]
   
    this.allAdmins();
  }
 
  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  allAdmins(){
    this.showadmins=true ;
    this.spinner.show()
    this.service.adminList().subscribe((res:any)=>{
    //  console.log("admins", res.data)
      this.spinner.hide()
      this.showadmins=false ;
      this.admins=res['data'] 
      // console.log("admins",this.admins)
    },(err:any)=>{
      this.spinner.hide();
    })
  }

  Delete(admin_id:any){
    this.service.deleteAdmin(admin_id).subscribe((res:any)=>{  
     this.toast.success('Success',res?.message)
      this.allAdmins()
    },
    (err:any)=>{
    //  console.log(err.error)
     this.toast.error('Error',err?.error?.message)
    })
  }

  Edit(admin:any) {
    const dialogRef = this.dialog.open(EditComponent, {
      width: '800px',
      data: {data:admin , title:'Edit Admin'},
    }); 
    dialogRef.afterClosed().subscribe((result:any) => {
     this.allAdmins()
    });
  }
 
}
