 
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrongRouteComponent } from './components/auth/errors/wrong-route/wrong-route.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './components/auth/login/login.component';

////////////////old Work///////////////////////
 
// import { UsersModule } from './components/users/users.module';

////////////////New Work///////////////////////
import { DashboardModule } from './components/dashboard/dashboard.modules';
import { ManagersModule } from './components/managers/managers.modules';
import { PartenersModule } from './components/parteners/partener.modules';
import { BankAccountsModule } from './components/bank-accounts/bank-accounts.modules';
import { NewRequestsModule } from './components/new-requests/new-requests.modules';
import { SettingsModule } from './components/general-settings/general-settings.modules';
import { ProfileModule } from './components/profile/profile.modules';
import { NotificationModule } from './components/notification/notification.modules';
import { RolesModule } from './components/roles/roles.modules';
 
 
const routes: Routes = [
  {path:'',component:LoginComponent},
  {
    path:'',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      {path:'home',loadChildren:()=>DashboardModule},
      {path:'mangers',loadChildren:()=>ManagersModule},
      {path:'partner',loadChildren:()=>PartenersModule},
      {path:'bank-accounts',loadChildren:()=>BankAccountsModule},
      {path:'new-requests',loadChildren:()=>NewRequestsModule},
      {path:'settings',loadChildren:()=>SettingsModule},
      {path:'profile',loadChildren:()=>ProfileModule},
      {path:'notifications',loadChildren:()=>NotificationModule},
      {path:'roles',loadChildren:()=>RolesModule},
       
      ////////////////old Work///////////////////////
      // {path:'users',loadChildren:()=>UsersModule},  //all ,add , edit permissions
    ]
  },
  {
    path        : '**',
    pathMatch   : 'full',
    component   : WrongRouteComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
