import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
  form!:FormGroup;
  submitted:boolean=false ; 
  deleted_eye:boolean=false;
  pass_type:any='password' ;
  data:any=[]
   
  constructor(titleService:Title ,private formbuilder:FormBuilder,  private spinner:NgxSpinnerService ,
    private toastr:ToastrService , private service:GlobalService){
      titleService.setTitle('اضف شريك')  
    }
    
  ngOnInit() {
    this.form = this.formbuilder.group({
      name:[null,Validators.required],
      phone:[null,Validators.required],
      email:[null,Validators.required],
      password:[null,Validators.required],
    })

    this.data=[
      {label:'الصفحة الرئيسية', path:'/home'},
      {label:'قائمة الشركاء', path:'/partner/list'} ,
      {label:'أضف شريك', active:true} 
    ]
   
  }

  ngOnChanges() {
    this.service.setRefresh(this.data)
  }

  showpassword(type:any) {
    this.deleted_eye=! this.deleted_eye ; 
    if(type=='password') {
     this.pass_type='text'
    }
    else {
     this.pass_type='password'
    }
  }

  
  get f():any {return this.form.controls}

  onSubmit(){
    this.submitted=true;
   if(this.form.invalid){ return}
    this.spinner.show();
    this.service.createPartner(this.form.value).subscribe((res:any)=>{
      this.spinner.hide();
      this.form.reset();
      this.submitted=false;
    //  console.log("success" ,res)
      this.toastr.success(res?.message ,'success') 
    },(err:any)=>{
      this.spinner.hide();
      this.toastr.error(err?.error?.message,'error');
    })
   }
}

