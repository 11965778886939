import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DashboardRoutingModule } from './dashboard-routing.modules';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxEchartsModule } from 'ngx-echarts';
// import { TranslateModule } from '@ngx-translate/core';
// import { InfiniteScrollModule } from 'ngx-infinite-scroll';
 
@NgModule({
  declarations: [DashboardComponent ], 
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,   
    MatIconModule,
    // TranslateModule,
    SharedModule,
    MatDialogModule ,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
  }),
  ],
//   providers:[
//     {
//       provide: LocationStrategy,
//       useClass: HashLocationStrategy,
//     },
//  ],
  
})
export class DashboardModule { }
