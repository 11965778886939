import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { BankAccountsRoutingModule } from './bank-accounts-routing.modules'; 
import { ListComponent } from './list/list.component';
import { AddComponent } from './add/add.component';
import { EditComponent } from './edit/edit.component';
 
@NgModule({
  declarations: [ListComponent,AddComponent,EditComponent ], 
  imports: [
    CommonModule,
    BankAccountsRoutingModule,
    FormsModule,
    ReactiveFormsModule,   
    MatIconModule,
    SharedModule,
    MatDialogModule,
   ]
})
export class BankAccountsModule { }
